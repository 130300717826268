<template>
  <BaseButton
      :disabled="subscriptionTypes.ANNUAL === subscriptionById?.subscriptionType && item.id === subscriptionById?.package"
      block
      @click.prevent="handleDialog(true)"
  >
    {{
      subscriptionTypes.ANNUAL === subscriptionById?.subscriptionType && item.id === subscriptionById?.package ? $t('selected') : $t('annual')
    }}
  </BaseButton>
  <teleport to="body">
    <transition name="popup">
      <PopupWrap
          v-if="showDialog"
          :max-width="700"
          :title="$t('upgradePackage')"
          @close-dialog="handleDialog(false)"
      >
        <form class="auth-form" @submit.prevent="">
          <div v-if="item" class="packageInfo">
            <div class="packageInfo-title">
              {{ item['name' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())] }}
              <span class="fz-16">
                ({{ $t('annual') }})
              </span>
            </div>
            <div class="packageInfo-price">
              <span>
                ${{ item.annualPrice }}
              </span>
            </div>
            <div class="packageInfo-desc">
              <div
                  class="package-upgrade__option"
                  v-html="
                    item &&
                    `${item['description' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())]}`
                  "
              ></div>
            </div>
          </div>
          <Discount @update="handleDiscount"/>
          <template v-if="isFullPackageDiscount">
            <div class="auth-btn d-flex justify-center mt-4">
              <BaseButton
                  style="min-width: 200px"
                  :loading="buyPackageLoading"
                  @on-click="handleBuyPackageWithFullDiscount"
              >
                {{ $t('confirm') }}
              </BaseButton>
            </div>
          </template>
          <template v-else>
            <Stripe @update-payment-data="paymentData = $event" ref="stripeElem"></Stripe>
            <div class="auth-btn d-flex justify-center mt-4">
              <BaseButton
                  style="min-width: 200px"
                  :loading="buyPackageLoading"
                  @on-click="handleBuyPackage"
              >
                {{ $t('payNow') }}
              </BaseButton>
            </div>
          </template>
        </form>
      </PopupWrap>
    </transition>
  </teleport>
</template>
<script setup>

import {subscriptionTypes} from "@/helpers/packages";
import BaseButton from "@/components/atoms/BaseButton.vue";
import {useSubscriptions} from "@/hooks/useSubscriptions";
import {computed, inject, ref, toRefs, defineProps, defineEmits} from "vue";
import Discount from "@/components/Discount.vue";
import PopupWrap from "@/components/PopupWrap.vue";
import Stripe from "@/components/features/Stripe.vue";
import {useI18n} from "vue-i18n";
import {useUser} from "@/composable/useUsers";
import {discountTypes} from "@/helpers/variables";
import {usePackages} from "@/hooks/usePackages";

const props = defineProps({
  item: {
    type: Object,
    require: true
  }
})
const emit = defineEmits(['on-success-buy'])

const {t} = useI18n();
const toast = inject('WKToast');
const {item} = toRefs(props)
const paymentData = ref(null);
const showDialog = ref(false)

const {subscriptionById} = useSubscriptions()
const {getProfile, profile} = useUser();
const {
  buyPackage,
  buyPackageLoading,
} = usePackages();

const discount = ref(null)
const handleDiscount = data => {
  discount.value = data
}

const isFullPackageDiscount = computed(() => {
  if (!discount.value) return false
  if (discount.value?.discountType === discountTypes.percentage) {
    return discount.value.discount >= 100
  } else {
    return discount.value.discount >= item.value.annualPrice
  }
})

const handleDialog = (status = false) => {
  discount.value = null
  showDialog.value = status;
};

const {
  fetchSubscriptionById
} = useSubscriptions()

const handleBuyPackage = async () => {
  const requestPayload = {
    userId: profile.value.userId,
    stripeCardToken: paymentData.value.token.id,
    packageId: item.value.id,
  };

  if (discount.value) {
    requestPayload.couponCode = discount.value.code
  }

  try {
    await buyPackage(requestPayload)
    getProfile({forceUpdate: true}).then(() => {
      fetchSubscriptionById({
        id: profile.value.ventrixSubscription,
        forceUpdate: true
      })
    })
    toast(t('success'));
    showDialog.value = false;
    emit('on-success-buy')
  } catch (err) {
    toast(t('declined'), {className: 'wk-alert'});
  }

}

const handleBuyPackageWithFullDiscount = async () => {
  const requestPayload = {
    userId: profile.value.userId,
    packageId: item.value.id,
    couponCode: discount.value.code
  };
  try {
    await buyPackage(requestPayload)
    getProfile({forceUpdate: true}).then(() => {
      fetchSubscriptionById({
        id: profile.value.ventrixSubscription,
        forceUpdate: true
      })
    })
    toast(t('success'));
    showDialog.value = false;
    emit('on-success-buy')
  } catch (err) {
    toast(t('declined'), {className: 'wk-alert'});
  }
}

</script>
