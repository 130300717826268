import {$httpAccounting} from '@/utils/https';

export default {
  async fetchSubscriptions({userId}) {
    const response = await $httpAccounting(`/subscriptions?user_id=${userId}&active=true`);
    return response.data;
  },
  async fetchSubscriptionById({id}) {
    const response = await $httpAccounting(`/subscriptions/${id}/`);
    return response.data;
  },
  async createSubscription(payload) {
    const response = await $httpAccounting.post(`/payments/package/subscription/`, payload);
    return response.data;
  },
  async cancelSubscription(payload) {
    const response = await $httpAccounting.post(`/subscriptions/cancel/`, payload);
    return response.data;
  },
};
