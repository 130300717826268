<template>
  <div class='download-app'>
    <a :href='`/apps/ventrix-${lastVersionSlug}-windows.zip`' download target='_blank' class='download-app__link'>
       <span class='download-app__link-logo' style='max-width: 40px'>
        <img src='@/assets/images/svg/windows-logo.svg' alt=''>
      </span>
      <span class='download-app__link-text'>
        <span class='download-app__link-text--title'>{{$t('downloadFor')}}</span>
        <span class='download-app__link-text--name'>Windows</span>
      </span>
    </a>
    <a :href='`/apps/ventrix-${lastVersionSlug}-universal-mac.zip`' download target='_blank' class='download-app__link'>
      <span class='download-app__link-logo'>
        <img src='@/assets/images/svg/apple-logo.svg' alt=''>
      </span>
      <span class='download-app__link-text'>
        <span class='download-app__link-text--title'>{{$t('downloadFor')}}</span>
        <span class='download-app__link-text--name'>Mac OS</span>
      </span>
    </a>
  </div>
</template>
<script setup>
import { useVersion } from '@/hooks/useVersion';
import { computed } from 'vue';

const {fetchVersion, version} = useVersion()
fetchVersion()

const lastVersionSlug = computed(() => version.value && version.value[0].versionSlug || '1.0.0')
</script>
<style lang='scss'>
.download-app {
  display: flex;
  justify-content: center;
  gap: 30px;
  @include respondTo(1350px){
    flex-wrap: wrap;
  }
  &__link{
    &-logo{
      max-width: 50px;
      img{
        width: 100%;
        object-fit: contain;
      }
    }
    &-text{
      display: block;
      padding-left: 10px;
      &--title{
        display: block;
        font-weight: 500;
        font-size: 16px;
        color: rgba($white, .7)
      }
      &--name{
        display: block;
        font-weight: 600;
        font-size: 20px;
        margin-top: 4px;
      }
    }
  }

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    color: $white;
    text-decoration: none;
    border: 2px solid $white;
    border-radius: 20px;
    min-width: 200px;
    min-height: 76px;
    width: calc(50% - 15px);
    @include respondTo(1350px){
      width: auto;
    }
    img {
      max-width: 100px;
    }
  }
}
</style>
