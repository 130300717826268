<template>
  <div
    class="main-select"
    :class="[
      size,
      {
        open: openSelect,
        disabled: disabled,
        readonly: readonly,
        error: !!v$.$errors.length,
        filled: filled,
        focus: isFocus,
        inNotEmpty: !!inputVal
      }
    ]"
    v-click-away="closeSelect"
  >
    <div class="main-select__label" v-if="!filled" v-show="!!label" :class="{'input--tooltip': !!tooltip}">
      {{ label }} {{ rules.required ? '*' : '' }}
      <template v-if="tooltip">
        <Tooltip :text="tooltip">
          <Icon name="question-circle" size="small" />
        </Tooltip>
      </template>
    </div>
    <label v-else :for="name">
      {{ label }}
    </label>
    <div class="main-select__input">
      <div class="main-select__block" @click="readonly ? false : (openSelect = !openSelect)">
        <input
          type="text"
          readonly
          :name="name"
          :disabled="disabled"
          :placeholder="placeholder"
          @focusin="isFocus = true"
          @focusout="isFocus = false"
          :value="currentValue"
        />
        <div class="main-select__clearable" v-show="clearable && inputVal" @click="handleSelect('')">
          <icon name="close"></icon>
        </div>
      </div>
      <slot name="outer"></slot>
    </div>
    <div class="main-textarea__hint" v-if="hint">
      {{ hint }}
    </div>
    <div class="main-input__errors" v-if="serverErrors">
      <span class="main-input__errors-item" :key="index" v-for="(item, index) in serverErrors">
        {{ item }}
      </span>
    </div>
    <div class="main-input__errors" v-if="v$.$errors.length">
      <span class="main-input__errors-item" :key="index" v-for="(item, index) in v$.$errors">
        {{ $t(`validationField_${item.$validator}`, {max: item.$params.max, min: item.$params.min}) }}
      </span>
    </div>
    <div class="main-select__hint" v-if="hint.length" :style="{color: hintColor, 'font-weight': hintWeight}">
      {{ hint }}
    </div>
    <div class="main-select__container">
      <transition name="slide-y-fade">
        <div class="main-select__wrap" v-show="openSelect" v-if="list">
          <ul class="main-select__list" v-if="multiple">
            <li
              v-for="item in list"
              class="main-select__multiItem"
              :class="{disabled: disabledItemsIds.includes(item.id)}"
              :key="item[itemValue]"
            >
              <BaseCheckbox
                :label="item[itemTitle]"
                :id="item.id"
                :name="item.id"
                :value="item.id"
                :checked="!!inputVal.find(e => e[itemValue] === item[itemValue])"
                @on-update="handleSelectMultiple(item)"
              />
            </li>
          </ul>
          <ul class="main-select__list" v-else>
            <li
              v-for="item in list"
              class="main-select__item"
              :class="{
                active: item[itemValue] === inputVal[itemValue],
                disabled: disabledItemsIds.includes(item.id)
              }"
              @click="handleSelect(item)"
              :key="item[itemValue]"
            >
              <div class="main-select__item-name">
                {{ item[itemTitle] }}
              </div>
            </li>
          </ul>
          <div class="main-select__loader" v-show="loading">
            <loader></loader>
          </div>
          <div class="main-select__empty" v-show="!loading && !list.length">
            <p>
              {{ $t('noData') }}
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/atoms/BaseCheckbox.vue';
import Icon from '@/components/atoms/Icon.vue';
import Loader from '@/components/atoms/Loader.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';

import {transformValidationRules} from '@/helpers/validation';

import useVuelidate from '@vuelidate/core';
import {clone} from 'lodash';
// eslint-disable-next-line no-unused-vars
import {computed, reactive, ref, toRefs, watchEffect} from 'vue';
import {mixin as VueClickAway} from 'vue3-click-away';

export default {
  name: 'BaseSelect',
  components: {
    BaseCheckbox,
    Icon,
    Loader,
    Tooltip
  },
  mixins: [VueClickAway],
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    defaultValue: {},
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabledItemsIds: {
      type: Array,
      default: () => []
    },
    hint: {
      type: String,
      default: ''
    },
    hintColor: {
      type: String,
      default: '#111'
    },
    hintWeight: {
      type: Number,
      default: 400
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemTitle: {
      type: String,
      default: 'name'
    },
    name: {
      type: String,
      default: Date.now().toString()
    },
    label: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String
    },
    serverErrors: {},
    returnObject: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    filled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  setup(props, {emit}) {
    const {defaultValue, itemValue, list, rules, multiple, itemTitle, returnObject} = toRefs(props);
    const openSelect = ref(false);
    const isFocus = ref(false);
    const state = reactive({
      inputVal: multiple.value ? [] : ''
    });

    const currentValue = computed(() => {
      if (multiple.value) {
        const item = clone(state.inputVal);
        return item.map(e => e[itemTitle.value]).join(', ');
      } else {
        return state.inputVal && state.inputVal[itemTitle.value];
      }
    });

    const handleSelect = item => {
      if (!item && item !== 0) {
        emit('update', null);
        openSelect.value = false;
        state.inputVal = '';
        return;
      }
      if (returnObject.value) {
        if (typeof item === 'object') {
          state.inputVal = item;
          emit('update', item);
        } else {
          const findObj = list.value.find(e => e[itemValue.value] === item);
          state.inputVal = findObj;
          emit('update', findObj);
        }
      } else {
        if (typeof item === 'object') {
          state.inputVal = item;
          emit('update', item[itemValue.value]);
        } else {
          const findObj = list.value.find(e => e[itemValue.value] === item);
          state.inputVal = findObj || '';
          if (findObj) {
            emit('update', findObj[itemValue.value]);
          }
        }
      }

      openSelect.value = false;
    };
    const handleSelectMultiple = item => {
      const findItemById = state.inputVal.find(e => e[itemValue.value] === item[itemValue.value]);
      if (findItemById) {
        const index = state.inputVal.findIndex(e => e[itemValue.value] === item[itemValue.value]);
        state.inputVal.splice(index, 1);
      } else {
        state.inputVal.push(item);
      }
      emit(
        'update',
        state.inputVal.filter(e => e).map(e => e.id)
      );
    };

    const closeSelect = () => {
      openSelect.value = false;
    };

    watchEffect(() => {
      if (multiple.value) {
        state.inputVal = list.value.filter(e => defaultValue.value.includes(e[itemValue.value]));
      } else {
        handleSelect(defaultValue.value);
      }
    });

    const validationRules = {
      inputVal: transformValidationRules(rules.value)
    };
    const v$ = useVuelidate(validationRules, state);

    return {
      isFocus,
      ...toRefs(state),
      currentValue,
      openSelect,
      handleSelect,
      closeSelect,
      handleSelectMultiple,
      v$
    };
  }
};
</script>
<style lang="scss">
.main-select {
  position: relative;
  margin-bottom: 25px;

  &.disabled > :not(.main-select__label) {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.filled {
    position: relative;

    &.large {
      .main-select__block::after {
        top: 21px;
        right: 25px;
      }

      .main-select__block::before {
        right: 57px;
      }

      input {
        height: 52px;
        padding: 5px 15px;
      }

      label {
        position: absolute;
        left: 15px;
        top: 17px;
        font-size: 16px;
        z-index: 4;
      }

      &.focus {
        label {
          position: absolute;
          left: 10px;
          top: 18px;
          font-size: 12px;
          transform: translateY(-24px);
          z-index: 4;
          background: $white;
          padding: 0 5px;
        }
      }

      &.inNotEmpty {
        label {
          position: absolute;
          left: 10px;
          top: 18px;
          font-size: 12px;
          transform: translateY(-24px);
          z-index: 4;
          background: $white;
          padding: 0 5px;
        }
      }
    }

    label {
      position: absolute;
      left: 10px;
      top: 13px;
      font-size: 14px;
      z-index: 4;
      transition: transform $trans, font-size $trans;
    }
  }

  &.readonly {
    pointer-events: none;
  }

  &.error {
    input {
      border-color: $red !important;
    }

    .main-select__block::before {
      background: $red !important;
    }
  }

  &__hint {
    font-size: 12px;
    margin-top: 2px;
    color: $silver;
    display: block;
    text-align: left;
  }

  &__clearable {
    position: absolute;
    right: 60px;
    top: 16px;
    z-index: 3;
    cursor: pointer;
  }
  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__block {
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      right: 47px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: rgba($black, 0.15);
      transition: background $trans;
      z-index: 2;
    }

    &::after {
      content: '';
      font-size: 0;
      display: block;
      width: 10px;
      height: 10px;
      background-image: url('../../assets/images/svg/arrow-down-ico.svg');
      background-position: center;
      background-repeat: no-repeat;
      transform: scale(1);
      margin-left: 5px;
      transition: transform $trans;
      position: absolute;
      right: 20px;
      top: 16px;
      z-index: 2;
    }
  }

  &.open {
    .main-select__block::after {
      transform: scale(1) rotate(-180deg);
    }

    .main-select__block::before {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    padding: 5px 10px;
    padding-right: 50px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: $black;
    position: relative;
    z-index: 1;
    border: 1px solid rgba($black, 0.15);
    transition: border-color $trans;
    background-color: $white;

    &:focus {
      border-color: rgba($black, 0.4);
    }
  }

  &__label {
    font-size: 14px;
    color: $silver;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    z-index: 5;
    @include respondTo(1950px) {
      font-size: 12px;
    }
  }

  &__empty {
    position: absolute;
    bottom: 18px;
    text-align: center;
    right: 0;
    left: 0;
    z-index: 3;

    p {
      font-size: 14px;
      color: $silver;
      text-align: center;
      font-weight: 500;
    }
  }

  &__loader {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    .loader {
      min-height: auto;
    }

    .preloader {
      width: 20px;
      height: 20px;
    }
  }

  &__list {
    position: relative;
    z-index: 2;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
    padding: 10px 0;
    min-height: 50px;
    box-shadow: 0 5px 40px rgba($black, 0.15);
    border-radius: 3px;
    background: $white;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fff;
      width: 2px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #2d3340;
      width: 2px;
      border-radius: 2px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      width: 2px;
    }
  }

  &__wrap {
    position: absolute;
    top: calc(100% + 15px);
    background: $white;
    left: 0;
    right: 0;
    z-index: 3001;
    border-radius: 3px;
  }

  &__multiItem {
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 42px;
      height: 42px;
      transition: background $trans;
      position: relative;
      cursor: pointer;
      padding: 10px 20px 10px 35px;
      font-weight: 400;
      background: $white;
      font-size: 16px;

      &::after {
        left: 10px;
        top: 12px;
      }

      &::before {
        left: 13px !important;
        top: 15px !important;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 42px;
    height: 42px;
    transition: background $trans;
    position: relative;
    cursor: pointer;
    padding: 10px 20px;
    font-weight: 400;
    background: $white;
    font-size: 16px;
    @include respondTo(580px) {
      padding: 10px 10px;
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.active {
      background: $white;

      &::after {
        content: '';
        position: absolute;
        right: 20px;
        top: 15px;
        width: 12px;
        height: 12px;
        background-image: url('../../assets/images/svg/checked-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &:last-child {
      .select__dropdown-right:after {
        display: none;
      }
    }

    &:hover {
      background: rgba($secondary, 0.1);
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      right: 0;
      background: rgba(0, 0, 0, 0.07);
      display: block;
    }

    &-ico {
      width: 40px;
      position: relative;
      padding-bottom: 2px;
    }

    &-name {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 1.3;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      @include respondTo(580px) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .main-select.country input {
    height: 45px;
    font-size: 14px;
  }
  .main-select input {
    height: 45px;
    font-size: 14px;
  }
  .main-select::after {
    bottom: 18px;
  }
  .main-select__flag {
    bottom: -1px;
  }
  .main-select__label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .main-select__list li {
    height: 42px;
  }
  .main-select__list li.active::after {
    top: 16px;
  }
  .main-select__list {
    padding: 5px 0;
    max-height: 200px;
  }
}
</style>
