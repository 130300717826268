import {generateQueryParams} from '@/helpers/url';

import $http from '@/utils/https';

const APIGW_URL = `${process.env.VUE_APP_APIGW_URL}/chatbots/open/app/version/list`;

export default {
  async fetchVersion(params) {
    const response = await $http(`${APIGW_URL}${generateQueryParams(params)}`);
    return response.data.data;
  }
};
