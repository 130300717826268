<template>
  <div class='full-width d-flex justify-between align-center row ml-sm-0 mr-sm-0'>
    <div class='col-5 d-none-sm'>
      <div class='auth-block'>
        <div class='auth-block__logo'>
          <img src='@/assets/images/ventrix-long.png' alt=''>
        </div>
        <div class='auth-block__title'>
          {{ $t('privateAndSecure') }}
        </div>
        <div class='auth-block__text'>
          {{ $t('privateAndSecureText1') }}
          <br><br>
          {{ $t('privateAndSecureText2') }}
          <br><br>
          {{ $t('privateAndSecureText3') }}
          <br><br>
          {{ $t('privateAndSecureText4') }}
        </div>
      </div>
    </div>
    <div class='col-7 col-sm-12'>
      <div class='auth-title'>
        {{ $t('finishRegistration') }}
      </div>
      <div class='auth-sub_title'>
        {{ $t('finishRegistrationSubText') }}
      </div>
      <form @submit.prevent='' class='auth-form'>
        <div class='row'>
          <div class='col col-6 col-sm-12'>
            <BaseInput
              name='firstName'
              type='firstName'
              :label="$t('firstName')"
              :ref='el => formRefs.push(el)'
              :autocomplete='false'
              filled
              size='large'
              :server-errors='serverValidationErrors.firstName'
              :value='form.firstName'
              :rules='{
                 maxLength: 128,
                 required: true,
              }'
              @on-update='form.firstName = $event'
            />
          </div>
          <div class='col col-6 col-sm-12'>
            <BaseInput
              name='lastName'
              type='lastName'
              :label="$t('lastName')"
              :ref='el => formRefs.push(el)'
              :autocomplete='false'
              filled
              size='large'
              :server-errors='serverValidationErrors.lastName'
              :value='form.lastName'
              :rules='{
                 maxLength: 128,
              }'
              @on-update='form.lastName = $event'
            />
          </div>
        </div>
        <BaseInput
          name='email'
          type='email'
          :label="$t('email')"
          :ref='el => formRefs.push(el)'
          :autocomplete='false'
          disabled
          filled
          size='large'
          :server-errors='serverValidationErrors.email'
          :value='form.email'
          :rules='{
             email: true,
             required: true,
          }'
          @on-update='form.email = $event'
        />
        <BaseInput
          name='username'
          type='username'
          :label="$t('formNickName')"
          :ref='el => formRefs.push(el)'
          :autocomplete='false'
          filled
          size='large'
          :value='form.nickname'
          :server-errors='nicknameErrors'
          :rules='{
             username: true,
             required: true,
             minLength: 3
          }'
          @on-update='handleNickname'
        />
        <BaseSelect
          id="package"
          name="package"
          :label="$t('package')"
          :ref="el => formRefs.push(el)"
          item-value="id"
          item-title="name"
          filled
          size="large"
          :loading="packagesLoading"
          :default-value="selectedPackage"
          :list="formattedPackages"
          :rules="{}"
          @update="selectedPackage = $event"
        ></BaseSelect>
        <div class="d-flex justify-start mt-5">
          <BaseButton
            size="large"
            style="min-width: 200px"
            :loading="updateProfileLoading || checkUniqNicknameLoading"
            @on-click="submit"
          >
            {{ $t('submit') }}
          </BaseButton>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
/* eslint-disable */
import BaseButton from '@/components/atoms/BaseButton.vue';
import BaseInput from '@/components/atoms/BaseInput.vue';
import BaseSelect from '@/components/atoms/BaseSelect.vue';

import {usePackages} from '@/hooks/usePackages';

import {useUser} from '@/composable/useUsers';

import {validationForm} from '@/helpers/validation';

import {debounce} from 'lodash';
import {computed, defineEmits, reactive, ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';

const emit = defineEmits(['complete']);
const i18n = useI18n();
const {t} = useI18n();

const availablePackages = computed(() => packages.value?.filter(e => e.isDespaxo));
const locale = i18n.locale.value;
const formRefs = ref([]);
const selectedPackage = ref(null);
const form = reactive({
  email: '',
  lastName: '',
  firstName: '',
  nickname: ''
});

const {packages, fetchPackages, packagesLoading, defaultPackageId} = usePackages();
fetchPackages({lang: i18n.locale.value});

const formattedPackages = computed(() => {
  return packages.value?.map(e => {
    return {
      ...e,
      name: `${e['name' + locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())]} ($${e.annualPrice})`
    }
  })
})

const {
  getProfile,
  profile,
  updateProfile,
  updateProfileError,
  updateProfileLoading,
  checkUniqNickname,
  checkUniqNicknameLoading,
  checkUniqNicknameResult
} = useUser();
getProfile();

const handleNickname = async val => {
  form.nickname = val;
  if (!val) return;
  await debounceCheckNickname(val);
};

const debounceCheckNickname = debounce(async val => {
  await checkUniqNickname({
    nickname: val
  });
}, 500);

const nicknameErrors = computed(() => {
  const isSameUsernameWithInputVal = profile.value?.nickname === form.nickname;
  const isExistNickname = checkUniqNicknameResult.value?.info.includes('is not unique');

  const error = [t('usernameAlreadyExist')];

  return serverValidationErrors.value.nickname || (isExistNickname && !isSameUsernameWithInputVal) ? error : '';
});
const serverValidationErrors = computed(() => {
  const errors = {};
  console.log(updateProfileError.value?.response);
  if (updateProfileError.value?.response.status === 404) {
    const errorBody = updateProfileError.value?.response.data.data;
    if (errorBody?.field) {
      errors[errorBody.field] = [t(`validationError_${errorBody.type}`, {name: errorBody.field})];
    }
  }
  if (updateProfileError.value?.response.status === 422) {
    const errorBody = updateProfileError.value?.response.data.data;
    Object.entries(errorBody).forEach(([key, value]) => {
      errors[key] = [t(`validationError_${value[0]}`, {name: key})];
    });
  }

  return errors;
});

watch(
  profile,
  () => {
    if (profile.value) {
      Object.keys(form).forEach(e => {
        form[e] = profile.value[e];
      });
    }
  },
  {immediate: true}
);

const prepareRequestPayload = params => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const submit = async () => {
  if (!validationForm(formRefs.value)) return;

  await updateProfile(prepareRequestPayload(form));
  emit('complete', {
    currentPackage: profile.value.ventrixSubscription || defaultPackageId.value,
    newPackage: selectedPackage.value
  });
};
</script>
