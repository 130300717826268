import api from '@/api/packages';

import {useSession} from '@/composable/useSession';

import {useMutation, useQuery} from '@/helpers/xQuery';

import {computed} from 'vue';
import {useSubscriptions} from "@/hooks/useSubscriptions";

const {fetchData: fetchPackages, data: packages, loading: packagesLoading} = useQuery('packages', api.fetchPackages);

export function usePackages() {
  const packageById = id => {
    const currentPackage = packages.value?.find(e => e.id === id);
    return currentPackage ? currentPackage : packages.value?.find(e => e.id === 1);
  };

  const {currentSession} = useSession();
  const {subscriptionById, subscriptions} = useSubscriptions()

  const defaultPackageId = computed(() => {
    const defaultPackage = packages.value?.find(e => e.isDefaultSelected);
    return defaultPackage ? defaultPackage.id : 1;
  });

  const ownerPackageId = computed(() => {
    const id = subscriptions.value?.results[0] && subscriptions.value?.results[0].package
    return id
  })

  const currentPackage = computed(() => {
    return currentSession.value
      ? packageById(ownerPackageId.value || defaultPackageId.value)
      : packageById(subscriptionById.value?.package || defaultPackageId.value);
  });

  const {buyPackage, buyPackageError, buyPackageLoading} = useBuyPackage();

  return {
    buyPackage,
    buyPackageError,
    buyPackageLoading,
    defaultPackageId,
    currentPackage,
    packageById,
    fetchPackages,
    packages,
    packagesLoading,
  };
}

const useBuyPackage = () => {
  const {sendRequest, loading: buyPackageLoading, error: buyPackageError} = useMutation();
  const buyPackage = payload => sendRequest(api.buyPackage, payload);

  return {
    buyPackage,
    buyPackageLoading,
    buyPackageError
  };
};
