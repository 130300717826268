import {useQuery} from "@/helpers/xQuery";
import api from "@/api/discount";

export const useDiscount = () => {
  const {fetchData: fetchDiscount, data: discount, loading: discountLoading, error: discountError} = useQuery('discount', api.fetchDiscount);

  return {
    fetchDiscount,
    discount,
    discountLoading,
    discountError
  }
}
