<template>
  <div class="payment-stripe" :class="{disabled: disabled}">
    <div class="payment-item__label">
      {{ $t(placeholderKey) }}
    </div>
    <div class="payment-form__input">
      <stripe-element :element="cardElement" @change="updatePaymentData($event)"> </stripe-element>
      <div class="payment-form__error" v-if="paymentData && paymentData.error">
        {{ paymentData.error.message }}
      </div>
      <div class="payment-form__error" v-if="stripeErrorResponse">
        {{ stripeErrorResponse.message }}
      </div>
    </div>
  </div>
</template>
<script>
import {ref} from 'vue';
import {StripeElement, useStripe} from 'vue-use-stripe';

const stripeStyles = {
  base: {
    iconColor: '#6d7070',
    color: '#323131',
    fontWeight: '500',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#69ef99'
    },
    '::placeholder': {
      color: '#434343'
    }
  },
  invalid: {
    iconColor: '#B83F39',
    color: '#B83F39'
  }
};
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    placeholderKey: {
      type: String,
      default: 'enterCardData'
    }
  },
  components: {
    StripeElement
  },
  setup(props, {emit}) {
    const paymentData = ref(null);
    const stripeErrorResponse = ref(null);

    const stripeKey = process.env.VUE_APP_HOLA_STRIPE_PUBLISHABLE_KEY;
    const {
      stripe,
      elements: [cardElement]
    } = useStripe({
      key: stripeKey,
      elements: [
        {
          type: 'card',
          options: {
            style: stripeStyles
          }
        }
      ]
    });
    const getToken = async () => {
      return await stripe.value.createToken(cardElement.value);
    };
    const updatePaymentData = async val => {
      paymentData.value = val;
      emit('update-payment-data', paymentData.value);

      if (paymentData.value?.complete) {
        stripeErrorResponse.value = null;
        const result = await getToken();
        if (result.error) {
          stripeErrorResponse.value = result.error;
        }
        emit('update-payment-data', {
          ...paymentData.value,
          ...result
        });
      }
    };

    return {
      stripe,
      getToken,
      paymentData,
      cardElement,
      stripeErrorResponse,
      updatePaymentData
    };
  }
};
</script>
<style lang="scss" scoped>
.StripeElement {
  border: 1px solid $silver-1;
  border-radius: 4px;
  height: 42px;
  padding: 10px;
  transition: border-color $trans;
  background: $white;

  &.StripeElement--invalid {
    border-color: $red;
  }

  &.StripeElement--focus {
    border-color: $silver;
  }
}

.payment-item__label {
  color: $silver;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
  @include respondTo(1950px) {
    font-size: 12px;
  }
}

.payment-stripe {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.payment-form__error {
  margin-top: 5px;
  color: $red;
  display: block;
  font-size: 12px;
  animation: shake 1 1s ease-in-out;
  text-align: left;
}
</style>
