<template>
  <div
    class="main-input"
    :class="[
      v$.$errors.length ? 'error' : 'valid-input',
      size,
      {disabled: disabled, filled: filled, focus: isFocus, readonly: readonly, inNotEmpty: !!state.inputVal}
    ]"
  >
    <div class="main-input__wrap">
      <div class="main-input__top d-flex justify-between align-center">
        <div class="main-input__label" v-if="!filled" v-show="!!label" :class="{'input--tooltip': !!tooltip}">
          {{ label }} {{ rules.required ? '*' : '' }}
          <template v-if="tooltip">
            <Tooltip :text="tooltip">
              <Icon name="question-circle" size="small" />
            </Tooltip>
          </template>
        </div>
        <label v-else :for="name">
          {{ label }}
        </label>
        <ul v-if="multilang" class="main-input__langs">
          <li
            v-for="lang in languageList"
            :key="lang"
            :class="{active: activeLang === lang}"
            @click="activeLang = lang"
          >
            {{ lang }}
          </li>
        </ul>
      </div>
      <template v-if="multilang">
        <div class="main-input__block" v-for="(lang, i) in languageList" :key="i">
          <slot name="prepend"></slot>
          <input
            v-if="lang === activeLang"
            v-model.trim="form[`${name}${lang.charAt(0).toUpperCase() + lang.slice(1)}`]"
            :type="type"
            :id="name"
            :name="name"
            :disabled="disabled"
            :readonly="readonly"
            :placeholder="filled ? '' : placeholder"
            :autocomplete="autocomplete ? 'on' : 'off'"
            @focusin="isFocus = true"
            @focusout="isFocus = false"
            @blur="v$.$touch()"
            @input="$emit('on-update', form)"
          />
          <slot name="append"></slot>
        </div>
      </template>
      <div v-else class="main-input__block">
        <slot name="prepend"></slot>
        <input
          :type="type"
          :id="name"
          :name="name"
          :disabled="disabled"
          :readonly="readonly"
          :placeholder="filled ? '' : placeholder"
          :autocomplete="autocomplete ? 'on' : 'off'"
          v-model.trim="state.inputVal"
          @focusin="isFocus = true"
          @focusout="isFocus = false"
          @blur="v$.$touch()"
          @input="$emit('on-update', $event.target.value)"
        />
        <slot name="append"></slot>
      </div>
      <div class="main-input__hint" v-if="successMessage">
        {{ successMessage }}
      </div>
      <div class="main-input__hint" v-if="hint">
        {{ hint }}
      </div>
      <div v-if="serverErrors" class="main-input__errors">
        <span class="main-input__errors-item" :key="index" v-for="(item, index) in serverErrors">
          {{ item }}
        </span>
      </div>
      <div class="main-input__errors" v-if="v$.$errors.length">
        <span class="main-input__errors-item" :key="index" v-for="(item, index) in v$.$errors">
          {{
            $t(`validationField_${item.$validator}`, {
              max: item.$params.max,
              min: item.$params.min,
              step: item.$params.step
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/atoms/Icon.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';

import {transformValidationRules} from '@/helpers/validation';

import useVuelidate from '@vuelidate/core';
import {reactive, ref, toRefs, watch} from 'vue';
import {useI18n} from 'vue-i18n';

export default {
  name: 'BaseInput',
  components: {
    Icon,
    Tooltip
  },
  props: {
    autocomplete: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    tooltip: {
      type: String
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    serverErrors: {},
    successMessage: {},
    hint: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String,
      default: 'normal'
    },
    multilang: {
      type: Boolean,
      default: false
    },
    multilangValue: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['on-update'],
  setup(props) {
    const i18n = useI18n();

    const activeLang = ref(i18n.locale.value);
    const {rules, value, name, multilangValue} = toRefs(props);

    const state = reactive({
      inputVal: ''
    });
    const isFocus = ref(false);

    const form = reactive({});
    i18n.availableLocales.forEach(e => {
      form[`${name.value}${e.charAt(0).toUpperCase() + e.slice(1)}`] = '';
    });

    const validationRules = {
      inputVal: transformValidationRules(rules.value)
    };
    const v$ = useVuelidate(validationRules, state);

    watch(
      value,
      () => {
        state.inputVal = value.value;
      },
      {immediate: true}
    );

    watch(
      multilangValue,
      () => {
        Object.entries(multilangValue.value).forEach(([key, value]) => {
          form[key] = value;
        });
      },
      {immediate: true, deep: true}
    );

    return {
      isFocus,
      state,
      v$,
      form,
      activeLang,
      languageList: i18n.availableLocales
    };
  }
};
</script>
<style lang="scss">
.main-input {
  position: relative;
  margin-bottom: 25px;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.small {
    input {
      padding-left: 5px;
      padding-right: 5px;
      height: 35px;
    }
  }

  &.filled {
    position: relative;

    &.large {
      input {
        height: 52px;
        padding: 5px 15px;
      }

      label {
        position: absolute;
        left: 15px;
        top: 17px;
        font-size: 16px;
        z-index: 4;
      }

      &.focus {
        label {
          position: absolute;
          left: 10px;
          top: 18px;
          font-size: 12px;
          transform: translateY(-24px);
          z-index: 4;
          background: $white;
          padding: 0 5px;
        }
      }

      &.inNotEmpty {
        label {
          position: absolute;
          left: 10px;
          top: 18px;
          font-size: 12px;
          transform: translateY(-24px);
          z-index: 4;
          background: $white;
          padding: 0 5px;
        }
      }
    }

    label {
      position: absolute;
      left: 10px;
      top: 13px;
      font-size: 14px;
      z-index: 4;
      transition: transform $trans, font-size $trans;
    }
  }

  &.error {
    .main-input__block::after {
      background: $red;
    }

    input {
      border-color: $red;

      &:focus {
        border: 1px solid $red;
      }
    }
  }

  &__langs {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      margin-left: 10px;
      text-transform: uppercase;
      padding: 4px 10px;
      border: 1px solid $black;
      border-radius: 3px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;

      &.active {
        background: $black;
        color: $white;
        cursor: default;
        pointer-events: none;
      }
    }
  }

  &__hint {
    margin-top: 5px;
    color: $silver;
    display: block;
    font-size: 12px;
    text-align: left;
  }

  &__errors {
    margin-top: 5px;
    color: $red;
    display: block;
    font-size: 12px;
    animation: shake 1 1s ease-in-out;
    text-align: left;

    &-item {
      display: block;
    }
  }

  &__label {
    color: $silver;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;

    .tooltip-hint {
      font-weight: 400;
    }

    @include respondTo(1950px) {
      font-size: 12px;
    }
  }

  &__block {
    overflow: hidden;
    position: relative;
  }

  input {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: border-color $trans;
    height: 42px;
    background: $white;

    &:focus {
      border-color: rgba(0, 0, 0, 0.4);
    }

    &[type='password'] {
      font-size: 20px;
      padding-bottom: 10px;
      font-weight: bold;

      &::-webkit-input-placeholder {
        color: #b3b3b3;
        opacity: 1;
        font-size: 16px;
        line-height: 1;
      }

      &:-moz-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #b3b3b3;
        opacity: 1;
      }
    }
  }
}
</style>
