import {$httpAccounting} from '@/utils/https';

export default {
  async fetchPackages() {
    const response = await $httpAccounting(`/packages/ventrix/?is_active=true`);
    return response.data.results;
  },
  async buyPackage(payload) {
    const response = await $httpAccounting.post(`/payments/package/`, payload);
    return response.data;
  },
  async generateSubscriptionUrl(payload) {
    const response = await $httpAccounting.post(`/payments/package/stripe-subscription/`, payload);
    return response.data;
  },
  async generateCancelSubscriptionUrl(payload) {
    const response = await $httpAccounting.post(`/payments/package/stripe-customer-portal/`, payload);
    return response.data;
  },
};
