<template>
  <BaseButton
      :block="btnBlock"
      @click.prevent="handleDialog(true)"
  >
    {{
      subscriptionTypes.MONTHLY === subscriptionById?.subscriptionType && item.id === subscriptionById?.package ? (btnCancelLabel || $t('cancel')) : $t('monthly')
    }}
  </BaseButton>
  <teleport to="body">
    <transition name="popup">
      <PopupWrap
          v-if="showDialog"
          :max-width="700"
          :title="isCancelSubscriptionFlow ? $t('cancelSubscription') : $t('upgradePackage')"
          @close-dialog="handleDialog(false)"
      >
        <form v-if="!isCancelSubscriptionFlow" class="auth-form" @submit.prevent="">
          <div v-if="item" class="packageInfo">
            <div class="packageInfo-title">
              {{ item['name' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())] }}
              <span class="fz-16">
                  ({{ $t('monthly') }})
                </span>
            </div>
            <div class="packageInfo-price">
                <span>
                  ${{ item.monthlyPrice }}
                </span>
              <span v-if="subscriptionTypes.MONTHLY === selectedSubscriptionType" class="fz-12 fw-400">
                {{ $t('perMonth') }}
              </span>
            </div>
            <div class="packageInfo-desc">
              <div
                  class="package-upgrade__option"
                  v-html="
                    item &&
                    `${item['description' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())]}`
                  "
              ></div>
            </div>
          </div>
          <Stripe @update-payment-data="paymentData = $event"></Stripe>
          <div class="auth-btn d-flex justify-center mt-4">
            <BaseButton
                style="min-width: 200px"
                :loading="createSubscriptionLoading"
                @on-click="handleSubscription"
            >
              {{ $t('payNow') }}
            </BaseButton>
          </div>
        </form>
        <div v-else>
          <p v-if="successCancelSubscription || (subscriptionById.status === 'canceled' && item.id === subscriptionById?.package)" class="pa-2 text-center lh-14 mb-4">
            {{ $t(`subscriptionStatus_cancel_subscribe`, {date: formatDate(subscriptionById.dateEnd, 'MMMM DD, YYYY')}) }}
          </p>
          <p v-else class="fz-16 lh-15 text-center mb-4">
            {{ $t('cancelSubscriptionAsk') }}
          </p>
          <div class="d-flex justify-center">
            <BaseButton
                v-if="successCancelSubscription || (subscriptionById.status === 'canceled' && item.id === subscriptionById?.package)"
                style="min-width: 200px"
                @on-click="handleDialog(false)"
            >
              {{ $t('close') }}
            </BaseButton>
            <BaseButton
                v-else
                style="min-width: 200px"
                :loading="cancelSubscriptionLoading"
                @on-click="handleCancelSubscription"
            >
              {{ $t('confirm') }}
            </BaseButton>
          </div>
        </div>
      </PopupWrap>
    </transition>
  </teleport>
</template>
<script setup>

import {subscriptionTypes} from "@/helpers/packages";
import BaseButton from "@/components/atoms/BaseButton.vue";
import PopupWrap from "@/components/PopupWrap.vue";
import Stripe from "@/components/features/Stripe.vue";
import {defineProps, inject, ref, toRefs, defineEmits} from "vue";
import {useSubscriptions} from "@/hooks/useSubscriptions";
import {useI18n} from "vue-i18n";
import {useUser} from "@/composable/useUsers";
import {formatDate} from "@/helpers/date";

const props = defineProps({
  item: {
    type: Object,
    require: true
  },
  btnCancelLabel: {
    type: String,
    default: ''
  },
  btnBlock: {
    type: Boolean,
    default: true,

  }
})

const emit = defineEmits(['on-success-subscribe', 'on-success-cancel-subscribe'])

const {t} = useI18n();
const toast = inject('WKToast');

const {item} = toRefs(props)

const paymentData = ref(null);
const selectedSubscriptionType = ref(subscriptionTypes.NOT_SPECIFIED)
const isCancelSubscriptionFlow = ref(false)
const showDialog = ref(false)
const successCancelSubscription = ref(false)

const {getProfile, profile} = useUser();
const {
  createSubscription,
  cancelSubscription,
  subscriptionById,
  cancelSubscriptionLoading,
  createSubscriptionError,
  createSubscriptionLoading,
  fetchSubscriptionById
} = useSubscriptions()


const handleDialog = (status = false) => {
  successCancelSubscription.value = false
  isCancelSubscriptionFlow.value = false
  showDialog.value = status;
  const isCurrentSubscriptionAreMonthly = subscriptionById.value.package === item.value.id && subscriptionById.value.subscriptionType === subscriptionTypes.MONTHLY
  isCancelSubscriptionFlow.value = isCurrentSubscriptionAreMonthly
};


const handleSubscription = async () => {
  const requestPayload = {
    userId: profile.value.userId,
    stripeCardToken: paymentData.value.token.id,
    packageId: item.value.id,
    stripePriceId: item.value.stripeMonthlyPriceId,
    email: profile.value.email
  };

  try {
    await createSubscription(requestPayload)
    getProfile({forceUpdate: true}).then(() => {
      fetchSubscriptionById({
        id: profile.value.ventrixSubscription,
        forceUpdate: true
      })
    })
    toast(t('success'));
    showDialog.value = false;
    emit('on-success-subscribe')
  } catch (err) {
    toast(t('declined'), {className: 'wk-alert'});
  }
}
const handleCancelSubscription = async () => {
  const requestPayload = {
    userId: profile.value.userId
  }
  try {
    await cancelSubscription(requestPayload)
    getProfile({forceUpdate: true}).then(() => {
      fetchSubscriptionById({
        id: profile.value.ventrixSubscription,
        forceUpdate: true
      })
    })
    toast(t('success'));
    successCancelSubscription.value = true
    emit('on-success-cancel-subscribe')
  } catch {
    console.log(createSubscriptionError.value);
    toast(t('declined'), {className: 'wk-alert'});
  }
}

</script>
