<template>
  <div class="mb-2">
    <div class="discount-field">
      <BaseInput
          class="mb-0"
          label="Discount code"
          :placeholder="$t('enterCode')"
          @on-update="handleDiscountCode"
      />
      <div v-if="discountLoading" class="discount-field__loader">
        <Loader size="small" />
      </div>
    </div>
    <div style="min-height: 16px" class="mt-1">
      <p v-if="discount && discountCode && discountCode.length > 2 && !discountLoading" class="fz-12">
      <span class="green-color">
        Promo code applied! You've received a discount:
        <strong>
          {{ discount.discountType === discountTypes.fixed ? '$' : '' }}
          {{ discount.discount }}
          {{ discount.discountType === discountTypes.percentage ? '%' : '' }}
        </strong>
      </span>
      </p>
      <p v-if="!discount && discountCode && discountCode.length > 2 && !discountLoading" class="fz-12">
      <span class="red-color">
       Invalid promo code. Please check the code and try again.
      </span>
      </p>
    </div>
  </div>
</template>
<script setup>

import {useDiscount} from "@/hooks/useDiscount";
import {ref, defineEmits} from "vue";
import BaseInput from "@/components/atoms/BaseInput.vue";
import {debounce} from "lodash";
import Loader from "@/components/atoms/Loader.vue";
import {discountTypes} from "@/helpers/variables";

const emit = defineEmits(['update'])

const discountCode = ref(null);

const {fetchDiscount, discount, discountLoading} = useDiscount()
const handleDiscountCode = debounce((code) => {
  discount.value = null
  discountCode.value = code
  fetchDiscount({code, forceUpdate: true}).then(resp => {
    emit('update', resp)
  })
}, 300)
</script>
<style lang="scss">
.discount-field{
  position: relative;
  &__loader{
    position: absolute;
    bottom: 11px;
    right: 10px;
  }
}
</style>
