<template>
  <div class='full-width d-flex justify-between align-center direction-sm-column-reverse row ml-sm-0 mr-sm-0'>
    <div class='col-5 col-sm-12'>
      <div class='auth-block direction-column justify-center d-flex' style='min-height: 65vh'>
        <div class='auth-block__logo'>
          <img src='@/assets/images/ventrix-long.png' alt=''>
        </div>
        <div class='auth-block__title'>
          {{ $t('downloadOurApp')}}
        </div>
        <div class='auth-block__text'>
          <AppLinks />
        </div>
      </div>
    </div>
    <div class='col-7 col-sm-12 pb-sm-5 mb-sm-4'>
      <div class='auth-back'>
        <a href='#' @click.prevent='$emit("back")'>
          <Icon name='chevron-left' />
          <span>
            {{ $t('back') }}
          </span>
        </a>
      </div>
      <div class="auth-title">
        {{ $t('buyPackageTitle') }}
      </div>
      <div class="auth-sub_title">
        {{ $t('buyPackageDesc') }}
      </div>
      <form class="auth-form">
        <div v-if="currentPackage" class="packageInfo">
          <div class="packageInfo-title">
            {{ currentPackage['name' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())] }}
          </div>
          <div class="packageInfo-desc">
            <div
                class="package-upgrade__option"
                v-html="
                currentPackage &&
                `${currentPackage['description' + $i18n.locale.replace(/(^\w|\s\w)/g, m => m.toUpperCase())]}`
              "
            ></div>
          </div>
        </div>
        <div class="main-input__errors" v-if="errors.length">
          <span class="main-input__errors-item" :key="index" v-for="(item, index) in errors">
            {{ item }}
          </span>
        </div>
        <div class="auth-btn mt-4">
          <div class="d-flex">
            <div class="col col-6 ml-1 mr-2">
              <h4 class="d-flex justify-center align-center mb-2 fz-18 red-color">
                  <span
                      v-if="subscriptionTypes.ANNUAL === profile.ventrixSubscriptionType && currentPackage.id === profile.ventrixPackage"
                      class="green-color package-item__check">
                    <Icon class="mr-2" name="check"></Icon>
                  </span>
                ${{ currentPackage.annualPrice }}
              </h4>
              <AnnualSubscription :item="currentPackage" @on-success-buy="$emit('complete');"/>
            </div>
            <div class="col col-6 mr-1 ml-2">
              <h4 class="d-flex justify-center align-center mb-2 fz-18 red-color">
                <span
                    v-if="subscriptionTypes.MONTHLY === profile.ventrixSubscriptionType && currentPackage.id === profile.ventrixPackage"
                    class="green-color package-item__check">
                  <Icon class="mr-2" name="check"></Icon>
                </span>
                ${{ currentPackage.monthlyPrice }}
              </h4>
              <div class="d-flex justify-start align-center">
                <MonthlySubscription :item="currentPackage" @on-success-subscribe="$emit('complete');"/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import MonthlySubscription from "@/components/packages/MonthlySubscription.vue";
import AnnualSubscription from "@/components/packages/AnnualSubscription.vue";
import Icon from '@/components/atoms/Icon.vue';

import {usePackages} from '@/hooks/usePackages';

import {useUser} from '@/composable/useUsers';

import {computed, defineEmits, ref} from 'vue';
import {useRoute} from 'vue-router';
import {subscriptionTypes} from "@/helpers/packages";
import {useSubscriptions} from "@/hooks/useSubscriptions";

defineEmits(['complete', 'update', 'back']);

const route = useRoute();
const errors = ref([]);

const {profile} = useUser();
const {
  packages,
} = usePackages();

const {fetchSubscriptionById} = useSubscriptions()
fetchSubscriptionById({id: profile.value.ventrixSubscription})

const currentPackage = computed(() => {
  return packages.value?.find(e => e.id === Number(route.query.package));
});

</script>
