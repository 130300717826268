import { useQuery } from '@/helpers/xQuery';
import api from '@/api/version';



export function useVersion() {
  const {
    fetchData: fetchVersion,
    data: version,
    loading: versionLoading
  } = useQuery('version', api.fetchVersion);

  const {
    fetchData: fetchOpenVersions,
    data: openVersion,
    loading: openVersionLoading
  } = useQuery('open-version', api.fetchVersion);


  return {
    fetchOpenVersions,
    openVersion,
    openVersionLoading,
    fetchVersion,
    version,
    versionLoading
  };
}
