import moment from 'moment/moment';

export function isCurrentDateInRange(startDate, endDate) {
  const currentDate = moment();
  const start = moment(startDate);
  const end = moment(endDate);

  return currentDate.isBetween(start, end, undefined, '[]');
}


export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const formatDateUtc = (date, format) => moment.utc(date).local().format(format);
