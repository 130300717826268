import {useMutation, useQuery} from "@/helpers/xQuery";
import api from "@/api/accounting/subscriptions";

const {
  fetchData: fetchSubscriptionById,
  data: subscriptionById,
  loading: subscriptionByIdLoading
} = useQuery('subscriptions-by-id', api.fetchSubscriptionById);
const {
  fetchData: fetchSubscriptions,
  data: subscriptions,
  loading: subscriptionsLoading
} = useQuery('subscriptions', api.fetchSubscriptions);

export function useSubscriptions() {
  const {cancelSubscription, cancelSubscriptionError, cancelSubscriptionLoading} = useCancelSubscription()
  const {createSubscription, createSubscriptionError, createSubscriptionLoading} = useCreateSubscription()

  return {
    fetchSubscriptions,
    subscriptions,
    subscriptionsLoading,
    createSubscription,
    createSubscriptionError,
    createSubscriptionLoading,
    cancelSubscription,
    cancelSubscriptionError,
    cancelSubscriptionLoading,
    fetchSubscriptionById,
    subscriptionById,
    subscriptionByIdLoading
  };
}

const useCancelSubscription = () => {
  const {sendRequest, loading: cancelSubscriptionLoading, error: cancelSubscriptionError} = useMutation();
  const cancelSubscription = payload => sendRequest(api.cancelSubscription, payload);

  return {
    cancelSubscription,
    cancelSubscriptionError,
    cancelSubscriptionLoading
  };
}

const useCreateSubscription = () => {
  const {sendRequest, loading: createSubscriptionLoading, error: createSubscriptionError} = useMutation();
  const createSubscription = payload => sendRequest(api.createSubscription, payload);

  return {
    createSubscription,
    createSubscriptionError,
    createSubscriptionLoading
  };
}
